import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "md:flex flex-col md:flex-row md:min-h-screen mt-0 mx-4" }
const _hoisted_2 = {
  class: "md:col-span-1 md:grid-cols-1",
  style: {"display":"unset"}
}
const _hoisted_3 = { class: "mx-auto w-full col-start-2 md:col-span-6 md:grid-cols-6 justify-self-center lg:justify-self-stretch" }
const _hoisted_4 = { class: "flex-col sm:flex-row md:items-center md:flex flex-wrap justify-center items-center justify-center" }
const _hoisted_5 = { class: "dark:text-gray-400" }
const _hoisted_6 = { class: "pt-16 sm:pt-16 md:pt-5 lg:pt-0 pb-0 container py-6 px-3 mx-auto flex flex-wrap flex-col md:flex-row lg:w-full" }
const _hoisted_7 = { class: "w-full py-3 md:py-0 sm:pb-10 lg:py-6 max-w-2xl my-2" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "pt-0 pb-0 container py-6 px-3 mx-auto xl:pr-64 break-words" }
const _hoisted_10 = {
  class: "list-inside font-bold",
  style: {"list-style-position":"inside"}
}
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "mb-5" }
const _hoisted_13 = { class: "font-medium text-md mb-2" }
const _hoisted_14 = { class: "font-medium text-md mb-2" }
const _hoisted_15 = { class: "font-medium text-md mb-2" }
const _hoisted_16 = { class: "font-medium text-md mb-2" }
const _hoisted_17 = { class: "font-medium text-md mb-2" }
const _hoisted_18 = { class: "font-medium text-md mb-2" }
const _hoisted_19 = { class: "font-medium text-md" }
const _hoisted_20 = { class: "" }
const _hoisted_21 = { class: "mb-5" }
const _hoisted_22 = { class: "font-medium text-md" }
const _hoisted_23 = { class: "font-medium text-md mb-2" }
const _hoisted_24 = {
  class: "list-inside font-bold",
  style: {"list-style-position":"inside"}
}
const _hoisted_25 = { class: "mb-5" }
const _hoisted_26 = { class: "" }
const _hoisted_27 = { class: "font-medium mb-2" }
const _hoisted_28 = {
  class: "list-decimal list-inside text-md font-bold mb-2",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_29 = { class: "font-medium" }
const _hoisted_30 = { class: "font-medium" }
const _hoisted_31 = { class: "font-medium" }
const _hoisted_32 = { class: "font-medium" }
const _hoisted_33 = { class: "font-medium" }
const _hoisted_34 = { class: "font-medium" }
const _hoisted_35 = { class: "font-medium" }
const _hoisted_36 = { class: "font-medium" }
const _hoisted_37 = { class: "font-medium" }
const _hoisted_38 = { class: "font-medium" }
const _hoisted_39 = { class: "mb-5" }
const _hoisted_40 = { class: "" }
const _hoisted_41 = { class: "font-medium mb-2" }
const _hoisted_42 = {
  class: "list-disc list-inside text-md font-bold mb-2",
  style: {"list-style-type":"disc","list-style-position":"inside"}
}
const _hoisted_43 = { class: "font-medium" }
const _hoisted_44 = { class: "font-medium" }
const _hoisted_45 = { class: "font-medium" }
const _hoisted_46 = { class: "font-medium" }
const _hoisted_47 = { class: "font-medium mb-2" }
const _hoisted_48 = { class: "font-medium mb-2" }
const _hoisted_49 = { class: "font-medium mb-2" }
const _hoisted_50 = { class: "font-medium mb-2" }
const _hoisted_51 = { class: "font-medium mb-2" }
const _hoisted_52 = { class: "font-medium mb-2" }
const _hoisted_53 = { class: "mb-5" }
const _hoisted_54 = { class: "" }
const _hoisted_55 = { class: "font-medium mb-2" }
const _hoisted_56 = { class: "font-medium mb-2" }
const _hoisted_57 = { class: "font-medium mb-2" }
const _hoisted_58 = {
  class: "list-decimal list-inside text-md font-bold",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_59 = { class: "font-medium" }
const _hoisted_60 = { class: "font-medium" }
const _hoisted_61 = { class: "font-medium" }
const _hoisted_62 = { class: "font-medium" }
const _hoisted_63 = { class: "font-medium" }
const _hoisted_64 = { class: "font-medium" }
const _hoisted_65 = { class: "font-medium" }
const _hoisted_66 = { class: "font-medium" }
const _hoisted_67 = { class: "font-medium" }
const _hoisted_68 = { class: "font-medium" }
const _hoisted_69 = { class: "font-medium" }
const _hoisted_70 = { class: "font-medium" }
const _hoisted_71 = { class: "" }
const _hoisted_72 = { class: "mb-5" }
const _hoisted_73 = { class: "font-medium text-md mb-2" }
const _hoisted_74 = {
  class: "list-decimal list-inside text-md font-bold",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_75 = { class: "font-medium" }
const _hoisted_76 = { class: "font-medium" }
const _hoisted_77 = { class: "font-medium" }
const _hoisted_78 = { class: "font-medium" }
const _hoisted_79 = { class: "font-medium" }
const _hoisted_80 = { class: "font-medium" }
const _hoisted_81 = { class: "font-medium" }
const _hoisted_82 = { class: "font-medium" }
const _hoisted_83 = { class: "font-medium" }
const _hoisted_84 = { class: "font-medium" }
const _hoisted_85 = { class: "font-medium" }
const _hoisted_86 = { class: "font-medium" }
const _hoisted_87 = { class: "font-medium" }
const _hoisted_88 = { class: "font-medium" }
const _hoisted_89 = { class: "font-medium" }
const _hoisted_90 = { class: "font-medium" }
const _hoisted_91 = { class: "font-medium" }
const _hoisted_92 = { class: "font-medium" }
const _hoisted_93 = { class: "font-medium" }
const _hoisted_94 = { class: "font-medium" }
const _hoisted_95 = { class: "font-medium text-md mb-2" }
const _hoisted_96 = { class: "font-medium text-md mb-2" }
const _hoisted_97 = { class: "font-medium text-md mb-2" }
const _hoisted_98 = { class: "font-medium text-md mb-2" }
const _hoisted_99 = { class: "font-medium text-md mb-2" }
const _hoisted_100 = { class: "font-medium text-md mb-2" }
const _hoisted_101 = {
  class: "list-decimal list-inside text-md font-bold mb-2",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_102 = { class: "font-medium" }
const _hoisted_103 = { class: "font-medium" }
const _hoisted_104 = { class: "font-medium" }
const _hoisted_105 = { class: "font-medium text-md" }
const _hoisted_106 = {
  class: "list-decimal list-inside text-md font-bold mb-2",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_107 = { class: "font-medium" }
const _hoisted_108 = { class: "font-medium" }
const _hoisted_109 = { class: "font-medium" }
const _hoisted_110 = { class: "font-medium" }
const _hoisted_111 = { class: "font-medium" }
const _hoisted_112 = { class: "font-medium" }
const _hoisted_113 = { class: "font-medium" }
const _hoisted_114 = { class: "font-medium" }
const _hoisted_115 = { class: "font-medium text-md mb-2" }
const _hoisted_116 = { class: "font-medium text-md mb-2" }
const _hoisted_117 = {
  class: "list-decimal list-inside text-md font-bold mb-2",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_118 = { class: "font-medium" }
const _hoisted_119 = { class: "font-medium" }
const _hoisted_120 = { class: "font-medium text-md" }
const _hoisted_121 = {
  class: "list-decimal list-inside text-md font-bold mb-2",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_122 = { class: "font-medium" }
const _hoisted_123 = { class: "font-medium" }
const _hoisted_124 = { class: "font-medium text-md mb-2" }
const _hoisted_125 = { class: "font-medium text-md" }
const _hoisted_126 = { class: "" }
const _hoisted_127 = { class: "mb-5" }
const _hoisted_128 = { class: "font-medium text-md mb-2" }
const _hoisted_129 = { class: "font-medium text-md mb-2" }
const _hoisted_130 = { class: "font-medium text-md mb-2" }
const _hoisted_131 = { class: "font-medium text-md" }
const _hoisted_132 = { class: "" }
const _hoisted_133 = { class: "mb-5" }
const _hoisted_134 = { class: "font-medium text-md mb-2" }
const _hoisted_135 = { class: "" }
const _hoisted_136 = { class: "mb-5" }
const _hoisted_137 = { class: "font-medium text-md mb-2" }
const _hoisted_138 = { class: "font-medium text-md mb-2" }
const _hoisted_139 = {
  class: "list-disc list-inside text-md font-bold mb-2",
  style: {"list-style-type":"disc","list-style-position":"inside"}
}
const _hoisted_140 = { class: "font-medium" }
const _hoisted_141 = { class: "font-medium" }
const _hoisted_142 = { class: "font-medium" }
const _hoisted_143 = { class: "font-medium" }
const _hoisted_144 = { class: "font-medium" }
const _hoisted_145 = { class: "font-medium" }
const _hoisted_146 = { class: "font-medium" }
const _hoisted_147 = { class: "font-medium text-md mb-2" }
const _hoisted_148 = { class: "font-medium text-md mb-2" }
const _hoisted_149 = { class: "font-medium text-md" }
const _hoisted_150 = { class: "" }
const _hoisted_151 = { class: "mb-5" }
const _hoisted_152 = { class: "font-medium text-md mb-2" }
const _hoisted_153 = { class: "font-medium text-md mb-2" }
const _hoisted_154 = { class: "font-medium text-md mb-2" }
const _hoisted_155 = { class: "font-medium text-md mb-2" }
const _hoisted_156 = {
  class: "list-inside font-bold",
  style: {"list-style-position":"inside"}
}
const _hoisted_157 = { class: "mb-5" }
const _hoisted_158 = { class: "" }
const _hoisted_159 = { class: "font-medium" }
const _hoisted_160 = { class: "mb-5" }
const _hoisted_161 = { class: "" }
const _hoisted_162 = { class: "font-medium" }
const _hoisted_163 = { class: "" }
const _hoisted_164 = { class: "mb-5" }
const _hoisted_165 = { class: "font-medium text-md mb-2" }
const _hoisted_166 = { class: "" }
const _hoisted_167 = { class: "mb-5" }
const _hoisted_168 = { class: "font-medium text-md mb-2" }
const _hoisted_169 = {
  class: "list-decimal list-inside text-md font-bold mb-2",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_170 = { class: "font-medium" }
const _hoisted_171 = { class: "font-medium" }
const _hoisted_172 = { class: "font-medium" }
const _hoisted_173 = { class: "font-medium" }
const _hoisted_174 = { class: "font-medium" }
const _hoisted_175 = { class: "font-medium" }
const _hoisted_176 = { class: "font-medium text-md mb-2" }
const _hoisted_177 = { class: "" }
const _hoisted_178 = { class: "mb-5" }
const _hoisted_179 = { class: "font-medium text-md mb-2" }
const _hoisted_180 = { class: "" }
const _hoisted_181 = { class: "mb-5" }
const _hoisted_182 = { class: "font-medium text-md mb-2" }
const _hoisted_183 = {
  class: "list-decimal list-inside text-md font-bold mb-2",
  style: {"list-style-type":"decimal","list-style-position":"inside"}
}
const _hoisted_184 = { class: "font-medium" }
const _hoisted_185 = { class: "font-medium" }
const _hoisted_186 = { class: "font-medium" }
const _hoisted_187 = { class: "font-medium" }
const _hoisted_188 = { class: "font-medium" }
const _hoisted_189 = { class: "font-medium" }
const _hoisted_190 = { class: "font-medium text-md mb-2" }
const _hoisted_191 = { class: "" }
const _hoisted_192 = { class: "mb-5" }
const _hoisted_193 = { class: "font-medium text-md mb-2" }
const _hoisted_194 = {
  class: "list-inside font-bold",
  style: {"list-style-position":"inside"}
}
const _hoisted_195 = { class: "mb-5" }
const _hoisted_196 = { class: "" }
const _hoisted_197 = { class: "font-medium" }
const _hoisted_198 = { class: "mb-5" }
const _hoisted_199 = { class: "" }
const _hoisted_200 = { class: "font-medium" }
const _hoisted_201 = { class: "" }
const _hoisted_202 = { class: "mb-5" }
const _hoisted_203 = { class: "font-medium text-md mb-2" }
const _hoisted_204 = { class: "" }
const _hoisted_205 = { class: "mb-5" }
const _hoisted_206 = { class: "font-medium text-md mb-2" }
const _hoisted_207 = { class: "" }
const _hoisted_208 = { class: "mb-5" }
const _hoisted_209 = { class: "font-medium text-md mb-2" }
const _hoisted_210 = { class: "font-medium text-md mb-2" }
const _hoisted_211 = { class: "font-medium text-md mb-2" }
const _hoisted_212 = { class: "font-medium text-md mb-2" }
const _hoisted_213 = { class: "font-medium text-md mb-2" }
const _hoisted_214 = { class: "font-medium text-md mb-2" }
const _hoisted_215 = {
  class: "list-inside font-bold",
  style: {"list-style-position":"inside"}
}
const _hoisted_216 = { class: "mb-5" }
const _hoisted_217 = { class: "" }
const _hoisted_218 = { class: "font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_sidebar)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", null, [
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("div", _hoisted_6, [
              _createVNode("div", _hoisted_7, [
                _createVNode("h1", _hoisted_8, _toDisplayString(_ctx.$t("terms.TermsTitle")), 1)
              ])
            ]),
            _createVNode("div", _hoisted_9, [
              _createVNode("ol", _hoisted_10, [
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("terms.IntroductionTitle")), 1),
                  _createVNode("div", _hoisted_12, [
                    _createVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("terms.IntroductionDetail")), 1),
                    _createVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("terms.IntroductionDetail2")), 1),
                    _createVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("terms.IntroductionDetail3")), 1),
                    _createVNode("p", _hoisted_16, _toDisplayString(_ctx.$t("terms.IntroductionDetail4")), 1),
                    _createVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("terms.IntroductionDetail5")), 1),
                    _createVNode("p", _hoisted_18, _toDisplayString(_ctx.$t("terms.IntroductionDetail6")), 1),
                    _createVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("terms.IntroductionDetail7")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("terms.IntellectualTitle")), 1),
                  _createVNode("div", _hoisted_21, [
                    _createVNode("p", _hoisted_22, _toDisplayString(_ctx.$t("terms.IntellectualDetail")), 1),
                    _createVNode("p", _hoisted_23, _toDisplayString(_ctx.$t("terms.IntellectualDetail2")), 1)
                  ]),
                  _createVNode("ol", _hoisted_24, [
                    _createVNode("div", _hoisted_25, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("terms.Intellectual2Title")), 1),
                        _createVNode("p", _hoisted_27, _toDisplayString(_ctx.$t("terms.Intellectual2Detail")), 1),
                        _createVNode("ul", _hoisted_28, [
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_29, _toDisplayString(_ctx.$t("terms.IntellectualList1Item1")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_30, _toDisplayString(_ctx.$t("terms.IntellectualList1Item2")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_31, _toDisplayString(_ctx.$t("terms.IntellectualList1Item3")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_32, _toDisplayString(_ctx.$t("terms.IntellectualList1Item4")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_33, _toDisplayString(_ctx.$t("terms.IntellectualList1Item5")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_34, _toDisplayString(_ctx.$t("terms.IntellectualList1Item6")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_35, _toDisplayString(_ctx.$t("terms.IntellectualList1Item7")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_36, _toDisplayString(_ctx.$t("terms.IntellectualList1Item8")), 1)
                          ])
                        ]),
                        _createVNode("p", _hoisted_37, _toDisplayString(_ctx.$t("terms.Intellectual2Detail2")), 1),
                        _createVNode("p", _hoisted_38, _toDisplayString(_ctx.$t("terms.Intellectual2Detail3")), 1)
                      ])
                    ]),
                    _createVNode("div", _hoisted_39, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_40, _toDisplayString(_ctx.$t("terms.Intellectual3Title")), 1),
                        _createVNode("p", _hoisted_41, _toDisplayString(_ctx.$t("terms.Intellectual3Detail")), 1),
                        _createVNode("ul", _hoisted_42, [
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_43, _toDisplayString(_ctx.$t("terms.IntellectualList2Item1")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_44, _toDisplayString(_ctx.$t("terms.IntellectualList2Item2")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("terms.IntellectualList2Item3")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_46, _toDisplayString(_ctx.$t("terms.IntellectualList2Item4")), 1)
                          ])
                        ]),
                        _createVNode("p", _hoisted_47, _toDisplayString(_ctx.$t("terms.Intellectual3Detail2")), 1),
                        _createVNode("p", _hoisted_48, _toDisplayString(_ctx.$t("terms.Intellectual3Detail3")), 1),
                        _createVNode("p", _hoisted_49, _toDisplayString(_ctx.$t("terms.Intellectual3Detail4")), 1),
                        _createVNode("p", _hoisted_50, _toDisplayString(_ctx.$t("terms.Intellectual3Detail5")), 1),
                        _createVNode("p", _hoisted_51, _toDisplayString(_ctx.$t("terms.Intellectual3Detail6")), 1),
                        _createVNode("p", _hoisted_52, _toDisplayString(_ctx.$t("terms.Intellectual3Detail7")), 1)
                      ])
                    ]),
                    _createVNode("div", _hoisted_53, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_54, _toDisplayString(_ctx.$t("terms.Intellectual4Title")), 1),
                        _createVNode("p", _hoisted_55, _toDisplayString(_ctx.$t("terms.Intellectual4Detail")), 1),
                        _createVNode("p", _hoisted_56, _toDisplayString(_ctx.$t("terms.Intellectual4Detail2")), 1),
                        _createVNode("p", _hoisted_57, _toDisplayString(_ctx.$t("terms.Intellectual4Detail3")), 1),
                        _createVNode("ul", _hoisted_58, [
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_59, _toDisplayString(_ctx.$t("terms.IntellectualList3Item1")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_60, _toDisplayString(_ctx.$t("terms.IntellectualList3Item2")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_61, _toDisplayString(_ctx.$t("terms.IntellectualList3Item3")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_62, _toDisplayString(_ctx.$t("terms.IntellectualList3Item4")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_63, _toDisplayString(_ctx.$t("terms.IntellectualList3Item5")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_64, _toDisplayString(_ctx.$t("terms.IntellectualList3Item6")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_65, _toDisplayString(_ctx.$t("terms.IntellectualList3Item7")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_66, _toDisplayString(_ctx.$t("terms.IntellectualList3Item8")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_67, _toDisplayString(_ctx.$t("terms.IntellectualList3Item9")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_68, _toDisplayString(_ctx.$t("terms.IntellectualList3Item10")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_69, _toDisplayString(_ctx.$t("terms.IntellectualList3Item11")), 1)
                          ]),
                          _createVNode("li", null, [
                            _createVNode("span", _hoisted_70, _toDisplayString(_ctx.$t("terms.IntellectualList3Item12")), 1)
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_71, _toDisplayString(_ctx.$t("terms.UserGeneratedTitle")), 1),
                  _createVNode("div", _hoisted_72, [
                    _createVNode("p", _hoisted_73, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail")), 1),
                    _createVNode("ul", _hoisted_74, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_75, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item1")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_76, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item2")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_77, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item3")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_78, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item4")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_79, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item5")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_80, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item6")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_81, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item7")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_82, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item8")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_83, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item9")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_84, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item10")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_85, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item11")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_86, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item12")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_87, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item13")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_88, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item14")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_89, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item15")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_90, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item16")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_91, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item17")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_92, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item18")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_93, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item19")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_94, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item20")), 1)
                      ])
                    ]),
                    _createVNode("p", _hoisted_95, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail2")), 1),
                    _createVNode("p", _hoisted_96, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail3")), 1),
                    _createVNode("p", _hoisted_97, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail4")), 1),
                    _createVNode("p", _hoisted_98, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail5")), 1),
                    _createVNode("p", _hoisted_99, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail6")), 1),
                    _createVNode("p", _hoisted_100, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail7")), 1),
                    _createVNode("ul", _hoisted_101, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_102, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item1")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_103, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item2")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_104, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item3")), 1)
                      ])
                    ]),
                    _createVNode("p", _hoisted_105, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail8")), 1),
                    _createVNode("ul", _hoisted_106, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_107, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item1")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_108, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item2")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_109, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item3")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_110, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item4")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_111, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item5")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_112, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item6")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_113, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item7")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_114, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item8")), 1)
                      ])
                    ]),
                    _createVNode("p", _hoisted_115, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail9")), 1),
                    _createVNode("p", _hoisted_116, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail10")), 1),
                    _createVNode("ul", _hoisted_117, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_118, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item1")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_119, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item2")), 1)
                      ])
                    ]),
                    _createVNode("p", _hoisted_120, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail11")), 1),
                    _createVNode("ul", _hoisted_121, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_122, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item1")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_123, _toDisplayString(_ctx.$t("terms.UserGeneratedList1Item2")), 1)
                      ])
                    ]),
                    _createVNode("p", _hoisted_124, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail12")), 1),
                    _createVNode("p", _hoisted_125, _toDisplayString(_ctx.$t("terms.UserGeneratedDetail13")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_126, _toDisplayString(_ctx.$t("terms.ThirdPartyTitle")), 1),
                  _createVNode("div", _hoisted_127, [
                    _createVNode("p", _hoisted_128, _toDisplayString(_ctx.$t("terms.ThirdPartyDetail")), 1),
                    _createVNode("p", _hoisted_129, _toDisplayString(_ctx.$t("terms.ThirdPartyDetail2")), 1),
                    _createVNode("p", _hoisted_130, _toDisplayString(_ctx.$t("terms.ThirdPartyDetail2")), 1),
                    _createVNode("p", _hoisted_131, _toDisplayString(_ctx.$t("terms.ThirdPartyDetail3")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_132, _toDisplayString(_ctx.$t("terms.PrivacyTitle")), 1),
                  _createVNode("div", _hoisted_133, [
                    _createVNode("p", _hoisted_134, _toDisplayString(_ctx.$t("terms.PrivacyDetail")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_135, _toDisplayString(_ctx.$t("terms.CopyrightTitle")), 1),
                  _createVNode("div", _hoisted_136, [
                    _createVNode("p", _hoisted_137, _toDisplayString(_ctx.$t("terms.CopyrightDetail")), 1),
                    _createVNode("p", _hoisted_138, _toDisplayString(_ctx.$t("terms.CopyrightDetail2")), 1),
                    _createVNode("ul", _hoisted_139, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_140, _toDisplayString(_ctx.$t("terms.CopyrightListItem1")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_141, _toDisplayString(_ctx.$t("terms.CopyrightListItem2")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_142, _toDisplayString(_ctx.$t("terms.CopyrightListItem3")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_143, _toDisplayString(_ctx.$t("terms.CopyrightListItem4")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_144, _toDisplayString(_ctx.$t("terms.CopyrightListItem5")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_145, _toDisplayString(_ctx.$t("terms.CopyrightListItem6")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_146, _toDisplayString(_ctx.$t("terms.CopyrightListItem7")), 1)
                      ])
                    ]),
                    _createVNode("p", _hoisted_147, _toDisplayString(_ctx.$t("terms.CopyrightDetail2")), 1),
                    _createVNode("p", _hoisted_148, _toDisplayString(_ctx.$t("terms.CopyrightDetail3")), 1),
                    _createVNode("p", _hoisted_149, _toDisplayString(_ctx.$t("terms.CopyrightDetail4")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_150, _toDisplayString(_ctx.$t("terms.ModificationTitle")), 1),
                  _createVNode("div", _hoisted_151, [
                    _createVNode("p", _hoisted_152, _toDisplayString(_ctx.$t("terms.ModificationDetail")), 1),
                    _createVNode("p", _hoisted_153, _toDisplayString(_ctx.$t("terms.ModificationDetail2")), 1),
                    _createVNode("p", _hoisted_154, _toDisplayString(_ctx.$t("terms.ModificationDetail3")), 1),
                    _createVNode("p", _hoisted_155, _toDisplayString(_ctx.$t("terms.ModificationDetail4")), 1)
                  ]),
                  _createVNode("ol", _hoisted_156, [
                    _createVNode("div", _hoisted_157, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_158, _toDisplayString(_ctx.$t("terms.Modification2Title")), 1),
                        _createVNode("p", _hoisted_159, _toDisplayString(_ctx.$t("terms.Modification2Detail")), 1)
                      ])
                    ]),
                    _createVNode("div", _hoisted_160, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_161, _toDisplayString(_ctx.$t("terms.Modification3Title")), 1),
                        _createVNode("p", _hoisted_162, _toDisplayString(_ctx.$t("terms.Modification3Detail")), 1)
                      ])
                    ])
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_163, _toDisplayString(_ctx.$t("terms.CorrectionsTitle")), 1),
                  _createVNode("div", _hoisted_164, [
                    _createVNode("p", _hoisted_165, _toDisplayString(_ctx.$t("terms.CorrectionsDetail")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_166, _toDisplayString(_ctx.$t("terms.DisclaimerTitle")), 1),
                  _createVNode("div", _hoisted_167, [
                    _createVNode("p", _hoisted_168, _toDisplayString(_ctx.$t("terms.DisclaimerDetail")), 1),
                    _createVNode("ul", _hoisted_169, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_170, _toDisplayString(_ctx.$t("terms.DisclaimerListItem1")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_171, _toDisplayString(_ctx.$t("terms.DisclaimerListItem2")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_172, _toDisplayString(_ctx.$t("terms.DisclaimerListItem3")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_173, _toDisplayString(_ctx.$t("terms.DisclaimerListItem4")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_174, _toDisplayString(_ctx.$t("terms.DisclaimerListItem5")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_175, _toDisplayString(_ctx.$t("terms.DisclaimerListItem6")), 1)
                      ])
                    ]),
                    _createVNode("p", _hoisted_176, _toDisplayString(_ctx.$t("terms.DisclaimerDetail2")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_177, _toDisplayString(_ctx.$t("terms.LimitationTitle")), 1),
                  _createVNode("div", _hoisted_178, [
                    _createVNode("p", _hoisted_179, _toDisplayString(_ctx.$t("terms.LimitationDetail")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_180, _toDisplayString(_ctx.$t("terms.IndemnificationTitle")), 1),
                  _createVNode("div", _hoisted_181, [
                    _createVNode("p", _hoisted_182, _toDisplayString(_ctx.$t("terms.IndemnificationDetail")), 1),
                    _createVNode("ul", _hoisted_183, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_184, _toDisplayString(_ctx.$t("terms.IndemnificationListItem1")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_185, _toDisplayString(_ctx.$t("terms.IndemnificationListItem2")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_186, _toDisplayString(_ctx.$t("terms.IndemnificationListItem3")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_187, _toDisplayString(_ctx.$t("terms.IndemnificationListItem4")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_188, _toDisplayString(_ctx.$t("terms.IndemnificationListItem5")), 1)
                      ]),
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_189, _toDisplayString(_ctx.$t("terms.IndemnificationListItem6")), 1)
                      ])
                    ]),
                    _createVNode("p", _hoisted_190, _toDisplayString(_ctx.$t("terms.IndemnificationDetail2")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_191, _toDisplayString(_ctx.$t("terms.UserDataTitle")), 1),
                  _createVNode("div", _hoisted_192, [
                    _createVNode("p", _hoisted_193, _toDisplayString(_ctx.$t("terms.UserDataDetail")), 1)
                  ]),
                  _createVNode("ol", _hoisted_194, [
                    _createVNode("div", _hoisted_195, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_196, _toDisplayString(_ctx.$t("terms.UserData2Title")), 1),
                        _createVNode("p", _hoisted_197, _toDisplayString(_ctx.$t("terms.UserData2Detail")), 1)
                      ])
                    ]),
                    _createVNode("div", _hoisted_198, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_199, _toDisplayString(_ctx.$t("terms.UserData3Title")), 1),
                        _createVNode("p", _hoisted_200, _toDisplayString(_ctx.$t("terms.UserData3Detail")), 1)
                      ])
                    ])
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_201, _toDisplayString(_ctx.$t("terms.ElectronicTitle")), 1),
                  _createVNode("div", _hoisted_202, [
                    _createVNode("p", _hoisted_203, _toDisplayString(_ctx.$t("terms.ElectronicDetail")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_204, _toDisplayString(_ctx.$t("terms.MiscellaneousTitle")), 1),
                  _createVNode("div", _hoisted_205, [
                    _createVNode("p", _hoisted_206, _toDisplayString(_ctx.$t("terms.MiscellaneousDetail")), 1)
                  ])
                ]),
                _createVNode("li", null, [
                  _createVNode("span", _hoisted_207, _toDisplayString(_ctx.$t("terms.ContactTitle")), 1),
                  _createVNode("div", _hoisted_208, [
                    _createVNode("p", _hoisted_209, _toDisplayString(_ctx.$t("terms.ContactDetail")), 1),
                    _createVNode("p", _hoisted_210, _toDisplayString(_ctx.$t("terms.ContactDetail2")), 1),
                    _createVNode("p", _hoisted_211, _toDisplayString(_ctx.$t("terms.ContactDetail3")), 1),
                    _createVNode("p", _hoisted_212, _toDisplayString(_ctx.$t("terms.ContactDetail4")), 1),
                    _createVNode("p", _hoisted_213, _toDisplayString(_ctx.$t("terms.ContactDetail5")), 1),
                    _createVNode("p", _hoisted_214, _toDisplayString(_ctx.$t("terms.ContactDetail6")), 1)
                  ]),
                  _createVNode("ol", _hoisted_215, [
                    _createVNode("div", _hoisted_216, [
                      _createVNode("li", null, [
                        _createVNode("span", _hoisted_217, _toDisplayString(_ctx.$t("terms.Contact2Title")), 1),
                        _createVNode("p", _hoisted_218, _toDisplayString(_ctx.$t("terms.Contact2Detail")), 1)
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}